import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { isUserLoggedIn } from 'src/utils/utils';
import { paths } from 'src/routes/paths';
import { ApiRoutes } from 'src/@core/constants';
import { useProfileContext } from 'src/pages/profile/ProfileContext';
import { useEffect } from 'react';
import { userLogout } from 'src/@core/api/common_api';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const UserData = isUserLoggedIn();
  const { shouldUpdateProfile, resetUpdateProfile } = useProfileContext();

  const OPTIONS = [
    {
      label: 'Profile',
      linkTo: paths.profile,
    },
    UserData?.u_type === 1
      ? {
        label: 'Web Setting',
        linkTo: paths.websetting,
      }
      : false,
  ].filter((item) => item !== false);

  const router = useRouter();

  const { logout } = useAuthContext();

  const popover = usePopover();

  const userdata = localStorage.getItem('deviceData');

  const handleLogout = async () => {
    try {
      await userLogout(userdata).then((res)=>{
        
      }).catch((err)=>{
        console.error(err)
      })
      await logout();
      popover.onClose();
      localStorage.clear();
      router.replace('/login');
      // clearIndexedDB();
      // dispatch(revertAll());
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  useEffect(() => {
    if (shouldUpdateProfile) {
      resetUpdateProfile();
    }
  }, [shouldUpdateProfile, resetUpdateProfile]);
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 50,
          height: 50,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={`${ApiRoutes.API_HOSTNAME}/profileimage/${UserData?.profile_img}`}
          alt={`${UserData?.full_name}`}
          sx={{
            width: 45,
            height: 45,
            border: (theme) => `solid 2px ${theme?.palette?.background?.default}`,
          }}
        >
          {UserData?.full_name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
        <Typography variant="subtitle2" noWrap>
            {UserData?.company_name}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {UserData?.first_name}  {UserData?.last_name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {UserData?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
