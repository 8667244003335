import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { isUserLoggedIn } from 'src/utils/utils';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  websetting: icon('ic_websetting'),
  bank: icon('ic_banking'),
  dealer: icon('ic_user'),
  status: icon('ic_filestatus'),
  addloan: icon('ic_addloan'),
  insurance: icon('ic_insurance'),
  // contestview: icon('ic_tour'),
  contest: icon('ic_gift'),
  disbusssegment: icon('ic_payoutsegment'),
  rejectedsegment: icon('ic_rejected_loan'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const userData = isUserLoggedIn();
  const adminRoutes = [];
  const staffRoutes = [];
  const user = isUserLoggedIn();

  const dashboard = {
    title: user.u_type === 1 ? t('Dashboard') : t('Dashboard'),
    path: paths.dashboard.root,
    icon: ICONS.dashboard,
  };

  const dealer = {
    title: t('Add Dealer'),
    path: paths.dealer,
    icon: ICONS.dealer,
  };

  const bank = {
    title: user.u_type === 1 ? t('Add Bank') : t('Payout Structure'),
    path: paths.bank,
    icon: ICONS.bank,
  };

  const file_status = {
    title: t('Add File Status'),
    path: paths.status,
    icon: ICONS.status,
  };

  const loan = {
    title: user.u_type === 1 ? t('Add Loan') : t('Loan'),
    path: paths.addloan,
    icon: ICONS.addloan,
  };

  const insurance = {
    title: user.u_type === 1 ? t('Add Insurance') : t('Insurance'),
    path: paths.insurance,
    icon: ICONS.insurance,
  };

  const contest = {
    title: user.u_type === 1 ? t('Add Contest') : t('Contest'),
    path: paths.contest,
    icon: ICONS.contest,
  };

  const disbuss_segment = {
    title: t('Disbuss Segment'),
    path: paths.disbusssegment,
    icon: ICONS.disbusssegment,
  };
  const rejected_segment = {
    title: t('Rejected Segment'),
    path: paths.rejectedsegment,
    icon: ICONS.rejectedsegment,
  };

  adminRoutes.push(
    dashboard,
    dealer,
    bank,
    file_status,
    loan,
    insurance,
    contest,
    disbuss_segment,
    rejected_segment
  );

  staffRoutes.push(dashboard, bank, loan, insurance, disbuss_segment, contest);

  const data = useMemo(
    () => [
      {
        items: userData?.u_type === 1 ? adminRoutes : staffRoutes,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return data;
}
