import Request from '.';
import { ApiRoutes } from '../constants';

export const UserLogin = async (data) => {
  const res = await Request.post(ApiRoutes.LOGIN, data);
  return res;
};

export const userLogout = async (data) => {
  const res = await Request.post(ApiRoutes.LOGOUT, data);
  return res;
};
