/* eslint-disable import/no-extraneous-dependencies */
import { IconButton, InputAdornment } from '@mui/material';
import moment from 'moment';
import Iconify from 'src/components/iconify';
import styled from 'styled-components';

export const IsNotValue = [
  "Invalid Date",
  null,
  "null",
  undefined,
  "undefined",
  "",
  "<p></p>",
  "<p></p>\n",
  "<p>&nbsp;</p>",
  "<p>&nbsp;</p>\n",
];

export const USER_STATUS_OPTIONS = [
  { value: 0, label: 'Active' },
  { value: 1, label: 'Inactive' },
];

export function passwordFieldEye(password) {
  return (
    <InputAdornment position="end">
      <IconButton onClick={password.onToggle} edge="end">
        <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
      </IconButton>
    </InputAdornment>
  );
}

export const Email_regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const defaultDateFilter = {
  start_date_time: moment().clone().startOf('month').format('DD-MM-YYYY'),
  end_date_time: moment().clone().endOf('month').format('DD-MM-YYYY'),
};

export const defaultInsuranceValues = {
  dellar_id: '',
  vehical_name: '',
  vehical_no: '',
  commission: '',
  premium_price: '',
  is_id: 0,
};

export const defaultDateTimeFilterWithoutFormat = {
  start_date_time: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  end_date_time: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
};

export const loanData1 = [
  { label: 'Dealer Name', value: 'dealer_name' },
  { label: 'Customer Name', value: 'customer_name' },
  { label: 'Customer Mobile', value: 'customer_mono' },
  { label: 'Generated Date', value: 'date' },
  { label: 'Vehical No', value: 'vehicle_no' },
  { label: 'Vehical Name', value: 'vehicle_name' },
  { label: 'Bank Name', value: 'bank_name' },
  { label: 'Bank Query', value: 'bank_query' },
  { label: 'Bank Conditions', value: 'conditions' },
  { label: 'Payment Released', value: 'paymentReleased' },
  { label: 'Approved Status', value: 'isApproved' },
  { label: 'Approved Date', value: 'approved_date' },
  { label: 'Approved Amount', value: 'approved_amount' },
  { label: 'Payable Amount', value: 'payable_amount' },
  { label: 'Percentage', value: 'percentage' },
  { label: 'Payout', value: 'payout' },
  { label: 'Remaining Amount ', value: 'remaining_amount' },
  { label: 'RTO Name Transfer', value: 'rtoNameTransfer' },
  { label: 'Status', value: 'status_name' },
];
// export const loanData2 = [
//   { label: 'bank_name', value: 'bank_name' },
//   { label: 'bank_query', value: 'bank_query' },
//   { label: 'bank_conditions', value: 'conditions' },
// ]

// export const loanData3 = [
//   { label: 'paymentReleased', value: 'paymentReleased' },
//   { label: 'isApproved', value: 'isApproved' },
//   { label: 'Approved Date', value: 'approved_date' },
//   { label: 'Approved Amount', value: 'approved_amount' },
//   { label: 'payable_amount', value: 'payable_amount' },
//   { label: 'percentage', value: 'percentage' },
// ]

// export const loanData4 = [
//   { label: 'payout', value: 'payout' },
//   { label: 'remaining_amount ', value: 'remaining_amount' },
//   { label: 'rtoNameTransfer', value: 'rtoNameTransfer' },
//   { label: 'status_name', value: 'status_name' },
// ]

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  padding: 10px;
`;

export const Progress = styled.div`
  position: relative;
  width: 80%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 4px;
  margin: 0 auto;
`;

export const Percent = styled.div`
  position: absolute;
  width: ${({ progress }) => progress}%;
  height: 100%;
  background: #cc843a;
  transition: width 1s;
  border-radius: 10px 0 0 10px;
`;

export const Steps = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 27px;
`;

export const Step = styled.div`
  position: absolute;
  width: 1px;
  height: 15px;
  background: #acaca6;
  padding-top: 10px;
  transition:
    background 1s,
    border 1s;
  transform: translateX(-50%);
`;

export const StepLabel = styled.div`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  white-space: nowrap;
  justify-content: space-between;
`;
