import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { Navigate } from 'react-router';
import { useRouter } from 'src/routes/hooks';
import { useAuthContext } from '../hooks';

export default function AuthGuard({ children }) {
  const router = useRouter();
  const { authenticated, unauthenticated } = useAuthContext();
  const [checked, setChecked] = useState(false);
  const check = useCallback(() => {
    if (!authenticated) {
      const href = window.location.pathname;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (unauthenticated) {
    return <Navigate to="/" />;
  }

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
