/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const contextValue = useMemo(
    () => ({
      showModal,
      openModal,
      closeModal,
    }),
    [showModal]
  );

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};
ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
