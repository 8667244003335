import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { Container, Typography } from '@mui/material';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
// import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  // const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {/* {lgUp ? renderHorizontal : renderNavVertical} */}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            // minHeight: 'calc(100vh - 60px)',
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
        <Container
          sx={{
            py: 1,
            ml: { lg: 12 },
            width: 'auto',
          }}
        >
          <Typography variant="caption" component="div">
            COPYRIGHT © {new Date().getFullYear()}{' '}
            <a href="https://www.truelinesolution.com/" target="_blank" rel="noopener noreferrer">
              Trueline Solution
            </a>
            <span className="d-none d-sm-inline-block">, All rights Reserved</span>
          </Typography>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 'calc(100vh - 34px)',
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
      <Container
        sx={{
          py: 1,
          ml: { lg: 35 },
          width: 'auto',
        }}
      >
        <Typography variant="caption" component="div">
          COPYRIGHT © {new Date().getFullYear()}{' '}
          <a href="https://www.truelinesolution.com/" target="_blank" rel="noopener noreferrer">
            Trueline Solution
          </a>
          <span className="d-none d-sm-inline-block">, All rights Reserved</span>
        </Typography>
      </Container>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
