const ROOTS = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  WEBSETTING: '/websetting',
  PROFILE: '/profile',
  DEALER: '/dealer',
  BANK: '/bank',
  STATUS: '/status',
  CONTEST:'/contest',
  CONTESTVIEW:'/contestview',
  PAGENOTFOUND: '/404',
  ADDLOAN: '/addloan',
  INSURANCE: '/insurance',
  FORGOTPASSWORD: '/forgotpassword',
  DISBUSSSEGMENT: '/disbusssegment',
  REJECTEDSEGMENT: '/rejectedsegment',
};

// ----------------------------------------------------------------------

export const paths = {
  page404: ROOTS.PAGENOTFOUND,

  auth: {
    login: ROOTS.LOGIN,
    forgotpassword:ROOTS.FORGOTPASSWORD
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  websetting: ROOTS.WEBSETTING,
  profile: ROOTS.PROFILE,
  dealer: ROOTS.DEALER,
  bank: ROOTS.BANK,
  status: ROOTS.STATUS,
  addloan: ROOTS.ADDLOAN,
  insurance:ROOTS.INSURANCE,
  forgotpassword:ROOTS.FORGOTPASSWORD,
  contest: ROOTS.CONTEST,
  contestview: ROOTS.CONTESTVIEW,
  disbusssegment: ROOTS.DISBUSSSEGMENT,
  rejectedsegment: ROOTS.REJECTEDSEGMENT,
};
