import Request from '.';
import { ApiRoutes } from '../constants';

export const globalSearch = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.GLOBALSEARCH, formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
