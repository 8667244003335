import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RouteGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const Websetting = lazy(() => import('src/pages/websetting/websetting'));
const Profile = lazy(() => import('src/pages/profile/profile'));
const Bank = lazy(() => import('src/pages/bank/bank'));
const Dealer = lazy(() => import('src/pages/dealer/dealer'));
const FileStatus = lazy(() => import('src/pages/fileStatus/fileStatus'));
const AddLoan = lazy(() => import('src/pages/addLoan/addloan'));
const Insurance = lazy(() => import('src/pages/insurance/addinsurance'));
const Contest = lazy(() => import('src/pages/contest/contest'));
const ContestView = lazy(() => import('src/pages/contestview/contestview'));
const DisbussSegment = lazy(() => import('src/pages/disbussFile'))
const RejectedSegment = lazy(() => import('src/pages/rejectedFile'))

export const dashboardRoutes = [
  {
    element: (
      <AuthGuard>
        <RouteGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RouteGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <IndexPage /> },
      { path: 'websetting', element: <Websetting /> },
      { path: 'profile', element: <Profile /> },
      { path: 'dealer', element: <Dealer /> },
      { path: 'bank', element: <Bank /> },
      { path: 'status', element: <FileStatus /> },
      { path: 'addloan', element: <AddLoan /> },
      { path: 'insurance', element: <Insurance /> },
      { path: 'contest', element: <Contest /> },
      { path: 'contestview', element: <ContestView /> },
      { path: 'disbusssegment', element: <DisbussSegment /> },
      { path: 'rejectedsegment', element: <RejectedSegment /> },

    ],
  },
];
