/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { defaultDateFilter } from 'src/@core/constants/variables';
import Iconify from 'src/components/iconify';

export default function FilterDateDisplay({
  filterData,
  getDataFunction,
  searchCall,
  setSearchCall,
  setFilterData,
  page = 'no',
  dateChange,
  setDateChange,
  DateChangeInsurance,
  setDateChangeInsurance,
}) {
  const [showIcon, setShowIcon] = useState(false);
  const loanDefaultDate = { start_date_time: null, end_date_time: null };

  useEffect(() => {
    if (page === 'addloan') {
      if (filterData?.start_date_time !== null && filterData?.end_date_time !== null)
        setShowIcon(true);
      else setShowIcon(false);
    } else {
      const dateHasChanged = !_.isEqual(
        { start_date_time: filterData?.start_date_time, end_date_time: filterData?.end_date_time },
        defaultDateFilter
      );
      setShowIcon(dateHasChanged);
    }
  }, [filterData]);

  const handleResetDate = () => {
    if (page === 'addloan') {
      getDataFunction({ ...filterData, ...loanDefaultDate });
      setFilterData({ ...filterData, ...loanDefaultDate });
      setSearchCall(false);
    } else {
      getDataFunction({ ...filterData, ...defaultDateFilter });
      setFilterData({ ...filterData, ...defaultDateFilter });
      setSearchCall(false);
    }

    if (page === 'addloan') setDateChange(true);
    else if (page === 'insurance') setDateChangeInsurance(true);
  };

  return (
    <>
      {filterData?.start_date_time !== null && filterData?.start_date_time !== null && (
        <Stack
          spacing={1}
          sx={{
            mr: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Typography variant="button">
            {`From: ${filterData?.start_date_time} To: ${filterData?.end_date_time}`}
          </Typography>

          {showIcon && (
            <IconButton size="small" onClick={handleResetDate}>
              <Iconify icon="tabler:x" color="red" />
            </IconButton>
          )}
        </Stack>
      )}
    </>
  );
}
