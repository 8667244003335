import Request from '.';
import { ApiRoutes } from '../constants';


export const getNotificationApi = async () => {
  try {
    const res = await Request.get(ApiRoutes.GETNOTIFICATION);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const readNotificationApi = async (data) => {
    try {
      const res = await Request.post(ApiRoutes.READNOTIFICATION,data);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  
 