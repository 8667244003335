import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { getWebsetting } from 'src/@core/api/websetting';
import { handleToast } from 'src/utils/toast';
import { ApiRoutes } from 'src/@core/constants';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const [websettingData, setWebsettingData] = useState();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const handleGetWebsetting = async () => {
    await getWebsetting()
      .then((response) => {
        setIsDataFetched(true);
        setWebsettingData(response?.data);
      })
      .catch((error) => handleToast(0, error?.response?.data?.description));
  };

  useEffect(() => {
    if (!isDataFetched) {
      handleGetWebsetting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataFetched]);

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 70,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img
        src={`${ApiRoutes.API_HOSTNAME}/logo/${websettingData?.company_logo}`}
        alt="Loan Sure"
        title="Loan Sure"
      />
    </Box>
  );
  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href={paths.dashboard.root} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
