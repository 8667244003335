import PropTypes from 'prop-types';

export default function RouteGuard({ children }) {
  
  return <>{children}</>;
}

RouteGuard.propTypes = {
  children: PropTypes.node,
};
