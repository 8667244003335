import Request from '.';
import { ApiRoutes } from '../constants';

export const getWebsetting = async () => {
  try {
    const res = await Request.get(ApiRoutes.GETWEBSETTING);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateWebsetting = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEWEBSETTING, formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendTestMail = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.TESTMAIL, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

