export const ApiRoutes = {
  API_HOSTNAME: process.env.REACT_APP_API_HOSTNAME,
  WEBSOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL,

  LOGIN: 'login',
  LOGOUT: 'logoutdevice',

  // WEBSETTING
  GETWEBSETTING: 'getwebsetting',
  UPDATEWEBSETTING: 'updatewebsetting',
  TESTMAIL: 'testmail',

  // PROFILE
  UPDATEPROFILE: 'updateprofile',

  // DEALER
  GETDEALER: 'getdealerFilter',
  INSERTDEALER: 'insertdealer',
  UPDATEDEALER: 'updatedealer',
  DELETEDEALER: 'deletedealer',
  UPDATEDEALERSTATUS: 'updatedealerstatus',
  CHECKEMAILPHONEEXIST: 'checkemailphoneexist',
  GETDEALERLIST: 'getdealer',

  // BANK WITH PERCENTAGE
  GETBANK: 'getbank',
  INSERTBANK: 'insertbank',
  UPDATEBANK: 'updatebank',
  DELETEBANK: 'deletebank',

  // FILE STATUS
  GETFILESTATUS: 'getfilestatus',
  INSERTFILESTATUS: 'insertfilestatus',
  UPDATEFILESTATUS: 'updatefilestatus',
  DELETEFILESTATUS: 'deletefilestatus',

  // Add Loan
  INSERTLOAN: 'insertLoan',
  GETLOAN: 'getLoan',
  UPDATELOAN: 'updateloan',
  DEKETELOAN: '/deleteLoan/?loan_id=',
  DELETERTOASSETS: 'deletertoassets',
  GETLOANDETAILSDATA: 'getloandetailsdata',
  GETLOANDISBUSSSEGMENTDATA: 'getloandisbusssegmentdata',
  GETPAYOUTTOTAL: 'getpayouttotal',
  // GETLOANDETAILSDATA: 'getloandetailsdata',
  DOWNLOADLOANPAYMENTDATA: 'downloadloanpaymentdata/',
  DOWNLOADMULTIPLELOANPAYMENTDATA: 'downloadLoanReport',
  UPDATELOANSTATUS: 'updateloanstatus',

  // INSURANCE
  GETINSURANCE: 'getinsurancesegment',
  INSERTINSURANCE: 'insertinsurancesegment',
  UPDATEINSURANCE: 'updateinsurancesegment',
  DELETEINSURANCE: 'deleteinsurancesegment/',
  TOTALINSURANCECOMMISSION: 'totalinsurancecommission',

  // FORGOT PASSWORD
  FORGOTPASSWORD: 'sendForgetPassMail',

  // CONTEST
  GETCONTEST: 'getusercontestdetails',
  INSERTCONTEST: 'insertcontest',
  UPDATECONTEST: 'updatecontest',
  DELETECONTEST: 'deleteContest/',
  GETCONTESTDETAIL: 'getallusercontestdetails',

   // GLOABL SEARCH
   GLOBALSEARCH:'globalSearch',
  
   // NOTIFICATION
   GETNOTIFICATION:"getnotification",
   READNOTIFICATION:"readnotification",

};
